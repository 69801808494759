:root {
  --pink: #fb7185; /* Rose 400 */
  --pink-hover: #f43f5e; /* Rose 500 */
}

/* Prevent Horizontal Scrolling */
html,
body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* General Styles */
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Reference images with absolute paths */
  background: url("../public/chantelle2.jpg") no-repeat center center fixed;
  background-blend-mode: overlay;
  background-size: cover;
  color: var(--pink);
}

/* Typography Colors and Shadows */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: var(--pink) !important;
  /* Removed text shadow for cleaner, professional look */
}

a:hover {
  color: var(--pink-hover);
}

/* Prevent Horizontal Scrolling */
html,
body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* Background Container */
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: url("../public/chantelle2.jpg") no-repeat center center fixed,
    url("../public/chantelle3.jpg") no-repeat center center fixed;
  background-size: cover;
  background-blend-mode: overlay;
}

/* App Container - Now Scrolls Instead of Body */
.app-container {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

/* Fixed Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  box-sizing: border-box;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Header Title */
.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--pink) !important;
}

/* Navigation Container */
.nav-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* Horizontal NavButtons (Desktop) */
.nav-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--pink) !important;
}

/* Navigation Buttons Styling */
.nav-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--pink);
  color: white;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-buttons button:hover {
  background-color: var(--pink-hover);
}

/* Burger Menu (Hidden by Default on Large Screens) */
.burger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--pink);
  padding: 5px;
}

/* Main Content */
.main-content {
  margin-top: 70px;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Shared Section Container */
.section-container {
  width: 90%; /* Using 90% of viewport width for consistency */
  max-width: 800px;
  margin: 35px auto;
  padding: 15px 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Section Header Divider */
.section-header {
  width: 100%;
  border-bottom: 1px solid rgba(251, 113, 133, 0.5);
  padding-bottom: 8px;
  margin-bottom: 16px;
}

/* Typography within Sections */
.section-container h1 {
  font-size: 1.5rem;
  margin-bottom: 12px;
  color: #007bff;
}

.section-container p {
  font-size: 1rem;
  line-height: 1.4;
  color: #333;
  margin-bottom: 10px;
}

/* Form Elements within Sections */
.section-container form {
  width: 100%;
}

.section-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 0.9rem;
}

.section-container input,
.section-container textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Section Button Styling */
.section-container button {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.section-container button:hover {
  background-color: #0056b3;
}

/* Link Styling within Sections */
.section-container a {
  color: #007bff;
  text-decoration: none;
}

.section-container a:hover {
  text-decoration: underline;
}

/* Submit Button Styling */
button[type="submit"] {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--pink);
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button[type="submit"]:hover {
  background-color: var(--pink-hover);
}

@media (max-width: 768px) {
  .nav-buttons {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 10px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .nav-buttons.open {
    display: flex;
  }
  .burger-menu {
    display: block;
  }
}

/* Override text color for specific sections */
.about-section * {
  color: navy !important;
}

.new-section * {
  color: black !important;
}

.coaching-section * {
  color: hotpink !important;
}
